<template>
  <!-- 新 - 首页 - 整合营销 -->
  <div class="courseCenter">
    <headPage />
    <carousel />
    <div class="shell_1">
      <!-- <p class="p_1">全景多维运营体系 高效支撑赋能服务</p> -->
      <h1 style=" padding: 3rem 0;
    text-align: center;font-size:34px">全景多维运营体系&nbsp;&nbsp;高效支撑赋能服务</h1>
      <div class="shell_1_1 b_1">
        <div>全面大数据服务能力</div>
      </div>
      <div class="shell_1_1 b_2">
        <div>实现大数据精准获客</div>
      </div>
      <div class="shell_1_1 b_3">
        <div>精准算法锁定目标客户</div>
      </div>
      <div class="shell_1_1 b_4">
        <div>帮助企业营销推广少走弯路</div>
      </div>
    </div>
    <div class="shell_2">
      <p class="p_1">全景多维运营体系 高效支撑赋能服务</p>
      <img src="../../assets/loginIndex/integratedMarketing_5.png" alt="" />
    </div>

    <bottomPage />
  </div>
</template>
<script>
import headPage from "../wrongAuthenticatePage/headPage.vue";
import bottomPage from "./bottomPage.vue";
import carousel from "../wrongAuthenticatePage/carousel.vue";
export default {
  name: "wrongAuthenticatePage_integratedMarketing",
  components: {
    headPage,
    bottomPage,
    carousel,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.courseCenter {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .shell_1 {
    width: 1168px;
    margin: auto;
    .p_1 {
      text-align: center;
      color: #3f4652;
      font-size: 26px;
      margin-bottom: 40px;
      margin-top: 60px;
    }
    .shell_1_1 {
      width: 49%;
      height: 245px;
      float: left;
      margin: 0 2% 20px 0;
      border-radius: 6px;
      position: relative;
      div {
        width: 100%;
        height: 80px;
        line-height: 80px;
        padding-left: 20px;
        background: rgba(0, 0, 0, 0.4);
        font-size: 20px;
        color: #fff;
        position: absolute;
        bottom: 0;
      }
      &:nth-child(2n + 1) {
        margin-right: 0 !important;
      }
    }
    .b_1 {
      background: url("../../assets/loginIndex/integratedMarketing_1.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_2 {
      background: url("../../assets/loginIndex/integratedMarketing_2.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_3 {
      background: url("../../assets/loginIndex/integratedMarketing_3.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .b_4 {
      background: url("../../assets/loginIndex/integratedMarketing_4.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .shell_2 {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: -4px;
    .p_1 {
      position: absolute;
      left: 50%;
      top: 8%;
      color: #fff;
      font-size: 26px;
      margin-left: -212px;
    }
    img {
      width: 100%;
    }
  }
}
</style>
